<template >
    <div class="row" v-if="data"> 
        <div class="col-12 col-lg-12 wrapper-box-fiche"> 
            <div class="box-white-fiche">
                <template v-if="data.prix_sweden">
                    <table class="comparateur2 text-center" v-if="data.prix_sweden.prix_sweden_price">
                        <thead>
                            <tr>
                                <th class="gray">manufacturer price ex vat SEK</th>
                                <th class="gray">retail price SEK</th>
                                <th class="gray">manufacturer price ex vat</th>
                                <th class="gray">retail price</th>
                                <th class="gray">reimbursed</th>
                                <th class="gray">date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in data.prix_sweden.prix_sweden_price" :key="'prix_sweden_price_'+key">
                                <td>
                                    {{value.manufacturer_price_ex_vat_sek ? value.manufacturer_price_ex_vat_sek : '-'}}
                                </td>
                                <td>
                                    {{value.retail_price_sek ? value.retail_price_sek : '-'}}
                                </td>
                                <td>
                                    {{value.manufacturer_price_ex_vat ? value.manufacturer_price_ex_vat : '-'}}
                                </td>
                                <td>
                                    {{value.retail_price ? value.retail_price : '-'}}
                                </td>
                                <td>
                                    {{value.reimbursed ? value.reimbursed : '-'}}
                                </td>
                                <td>
                                    {{value.date ? value.date : '-'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
            </div>
        </div>
    </div>   
</template>


<script>
export default {
  name: "prix_sweden",
  components: {},
  methods: {},
  mounted() {
    if (this.$i18n.locale == "de") {
      this.lang = "de";
    }
  },
  updated() {},
  computed: {
      data() {
            return this.$store.getters['detail/data']
        }
  },
  unmounted() {},
};
</script>